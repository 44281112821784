import jquery from 'jquery';
import lazysizes from 'lazysizes';
import '../bootstrap/javascripts/bootstrap-sprockets.js';
import '../components/loading/loading.js';
// import es6Promise from 'es6-promise';

import AOS from 'aos';

import './svgs.js';
import '../style/commons.scss';


AOS.init({
    useClassNames: true,
    initClassName: false,
    animatedClassName: 'animated',
});

window.jQuery = jquery;
es6Promise.polyfill();
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.customMedia = {
    '--xs': '(max-width: 479px)',
    '--sm': '(max-width: 767px)',
    '--md': '(max-width: 991px)',
    '--lg': '(max-width: 1199px)',
};


if('ontouchend' in document){
    import(/* webpackChunkName: "fastclick" */ 'fastclick')
    .then((FastClick)=>{
        FastClick.attach(document.body);
        console.log('import fastclick');
    });
}


// $.ajax({
//     type: 'POST', 
//     url: 'http://rap2api.taobao.org/app/mock/86251/api/getCustomer',
//     data: {page:1}, 
//     async: true,
//     crossDomain: true == !(document.all),
//     success: (msg)=>{ 
//         console.log('msg',msg);
//     }
//  });

// 头部nav，手机模式下展开，关闭
const hdnavMenu = $('#hdnav-menu');
hdnavMenu.on('click',()=>{
    const  hdnav = $('#hdnav');
    const hideNav = ()=>{
        $('html').removeClass('enablescroll');
        $('body').removeClass('enablescroll');
        $('.modal-backdrop').remove();
        hdnav.removeClass('show-navlist');
    }
    const showNav =()=>{
        $('html').addClass('enablescroll');
        $('body').addClass('enablescroll');
        $(document.createElement('div')).addClass('modal-backdrop fade in').insertAfter(hdnavMenu);
        hdnav.addClass('show-navlist');
    }

    hdnav.hasClass('show-navlist') ? hideNav() : showNav();
    $('#hdnav-mask').on('click',()=>hideNav());
});

// 头部nav点击登录按钮
$('#hdnav-login').on('click',()=>{
    $('#lgreg-modal').modal('show');
    $('#lgreg-tab-login a').tab('show');   
});
//  头部nav点击注册按钮
$('#hdnav-reg').on('click',()=>{
    $('#lgreg-modal').modal('show');
    $('#lgreg-tab-reg a').tab('show');  
});

// 登录弹框-忘记密码
$('#lgreg-forgetpwd').on('click',(e)=>{
    $('#lgreg-tab').addClass('show-forgetpwd');
    // e.preventDefault();
    $('#lgreg-tab-forgetpwd a').tab('show');
}); 
// 找回密码弹框-返回登录
$('#lgreg-tab-return').on('click',(e)=>{
    $('#lgreg-tab').removeClass('show-forgetpwd');
    $('#lgreg-tab-login a').tab('show'); 
});
// 找回密码弹框-下一步按钮
$('#lgreg-next').on('click',(e)=>{
    $('#lgreg-tab-next a').tab('show'); 
});
// 找回密码弹框-修改按钮
$('#lgreg-modify').on('click',(e)=>{
    $('body').loading('show');
    setTimeout(() => {
        $('#lgreg-tab-modify a').tab('show'); 
        $('body').loading('hide');
    }, 1000);
});
// 修改成功后，立即登录
$('#lgreg-suc-login').on('click',()=>{
    $('#lgreg-tab').removeClass('show-forgetpwd');
    $('#lgreg-tab-login a').tab('show');
});








if(module && module.hot){
    module.hot.accept();
}
	

