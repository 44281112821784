
+function ($) {
    'use strict';
  
    // Loading CLASS DEFINITION
    // ====================

    var Loading = function (element) {
      // jscs:disable requireDollarBeforejQueryAssignment
      this.element = $(element)
      // jscs:enable requireDollarBeforejQueryAssignment
    }  
  
    Loading.prototype.show = function () {
        $('body').append('<div class="loading-backdrop"></div>');
        this.element.append('<div class="loading"><div class="loading_inner"><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div></div>');
        if($('html').hasClass('ie9')){
            var that = this;
            this.rotateNumber = 0;
            this.timer = setInterval(function(){
                that.rotateNumber = that.rotateNumber + 1;
                $('.loading_inner').css({
                    '-ms-transform':'rotate('+ that.rotateNumber % 12 * 30 +'deg)',
                });
                if(that.rotateNumber >=12) that.rotateNumber =0;
            },150);
        }   
    }

    Loading.prototype.hide = function () {
        console.log('hide');
        if($('html').hasClass('ie9')) clearInterval(this.timer);     
        $('.loading-backdrop').remove();
        $('.loading').remove();
        
        
    }
  
  
    // Loading PLUGIN DEFINITION
    // =====================
  
    function Plugin(option) {
      return this.each(function () {
        var $this = $(this)
        var data = new Loading(this);
        
  
        if (typeof option == 'string') data[option]();
        if (typeof option == 'undefined') data['show']();
      })
    }
  
    var old = $.fn.loading
  
    $.fn.loading             = Plugin
    $.fn.loading.Constructor = Loading
  
  
    // loading NO CONFLICT
    // ===============
  
    $.fn.loading.noConflict = function () {
      $.fn.loading = old
      return this
    }
  

  }(jQuery);
  